/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";



.ios body.dark {
    --ion-item-background: #1c1c1e;
  }
  
  ion-card {
    --background-darker: var(--ion-color-step-200, #e9edf3);
  
    --text-dark: var(--ion-color-step-850, #27323f);
    --text-darker: var(--ion-color-step-950, #0e151f);
  
    --text-light: var(--ion-color-step-650, #5b708b);
    --text-lighter: var(--ion-color-step-550, #73849a);
    --text-lightest: var(--ion-color-step-300, #b2becd);
  }

  .coworker-card .header-img {
    height: 60px;
    width: 100%;
  }
  
  .coworker-card h1 {
    font-weight: 600;
    font-size: 24px;
  }
  
  .coworker-card p {
    color: var(--text-light);
  }
  
  .coworker-card ion-icon {
    align-self: start;
    margin-top: 10px;
    margin-right: 29px;
    color: var(--text-lightest);
  }

  .small-text {
    font-size:small;         
  }


  .my-custom-modal-css .modal-wrapper {
    top: 10%;
    position: absolute; 
    display: block;  
    border-radius: 25px;
 }


 
  ion-tab-button.tab-selected {
    ion-label {
      color: var(--ion-color-primary) !important;
      --ion-color-base: var(--ion-color-primary) !important;
    }
  }
